import { Trans, useTranslation } from "@bounce/i18n"

import { useAppStoreUrl } from "../../../hooks/useAppStoreUrl"

import bagsImg from "@bounce/assets/illustrations/luggage-01@2x.webp"
import enjoyImg from "@bounce/assets/illustrations/man-with-camera@2x.webp"
import bookImg from "@bounce/assets/illustrations/phone-ui-01@2x.webp"

export type UseStepsOptions = {
  variant?: "app-download" | "book-online" | "find-closest-location"
  from?: "AppDownload" | "City" | "Home" | "Poi"
}

export const useSteps = ({ variant = "app-download", from }: UseStepsOptions) => {
  const { t } = useTranslation()
  const { href, onClick } = useAppStoreUrl({
    app: "luggageStorage",
    eventProps: { location: "HowItWorks", cta: "HowItWorks" + from },
  })

  const downloadAppStep = {
    src: bookImg,
    title: t("cmp.howItWorksSecondaryNextLuggageStorage.step1TitleV3", "Book in a few taps"),
    description: (
      <Trans
        i18nKey="cmp.howItWorksSecondaryNextLuggageStorage.step1DescriptionV3"
        defaults="<span>Tell us when you want to store your stuff, pick a convenient location, and pay online.</span><br /><br /><btn>Download the app</btn>"
        components={{
          span: <span />,
          br: <br />,
          btn: (
            <a
              href={href}
              onClick={onClick}
              rel="nofollow"
              className="font-medium text-primary-700 transition-all hover:text-primary-800"
            />
          ),
        }}
      />
    ),
  }

  const bookOnlineOrAppStep = {
    ...downloadAppStep,
    title: t("cmp.howItWorksSecondaryNextLuggageStorage.step1Title", "Book online or on the app"),
    description: (
      <Trans
        i18nKey="cmp.howItWorksSecondaryNextLuggageStorage.step1DescriptionV2"
        defaults="<span>Get the app and choose a convenient location. Your BounceShield™ Protection is activated upon booking online.</span><br /><br /><btn>Download the app</btn>"
        components={downloadAppStep.description.props.components}
      />
    ),
  }

  const bookOnlineStep = {
    ...bookOnlineOrAppStep,
    description: t(
      "cmp.howItWorksSecondaryNextLuggageStorage.bookOnlineStepDescription",
      "Get the app and choose a convenient location. Your BounceShield™ Protection is activated upon booking online.",
    ),
  }

  const getInitialStep = () => {
    if (variant === "app-download") {
      return downloadAppStep
    }

    if (variant === "book-online") {
      return bookOnlineOrAppStep
    }

    return bookOnlineStep
  }

  return [
    getInitialStep(),
    {
      src: bagsImg,
      title: t("cmp.howItWorksSecondaryNextLuggageStorage.step2TitleV2", "Head to the store"),
      description: t(
        "cmp.howItWorksSecondaryNextLuggageStorage.step2DescriptionV3",
        "Drop off your bags by showing your confirmation to a store employee.",
      ),
    },
    {
      src: enjoyImg,
      title: t("cmp.howItWorksSecondaryNextLuggageStorage.step3Title", "Enjoy the day"),
      description: t(
        "cmp.howItWorksSecondaryNextLuggageStorage.step3DescriptionV2",
        "Do whatever you’d like, then show your confirmation to pick up your stuff.",
      ),
    },
  ]
}
