import { useMemo } from "react"

import { Trans, useTranslation } from "@bounce/i18n"

import { FeatureFlag, useFeatureFlag } from "../../../config/flags"
import type { FAQ } from "../../../utils/boilerplate"

export const useFAQs = () => {
  const { t } = useTranslation()
  const [isBackpacksEnabled] = useFeatureFlag(FeatureFlag.Backpacks)

  const faqs: FAQ[] = useMemo(
    () => [
      {
        id: "How does Bounce luggage storage work?",
        question: t("cmp.luggageStorageFaqs.howDoesBounceWork.question", "How does Bounce luggage storage work?"),
        answer: (
          <Trans i18nKey="cmp.luggageStorageFaqs.howDoesBounceWork.answer">
            We partner with local businesses around the world who have secure spaces to store luggage for travelers.
            Just tell us how many bags you have and when you need storage, and we'll show you convenient places you can
            keep your stuff safe while you explore.
            <br />
            <br />
            Booking online is super easy and only takes a couple of minutes. To keep our partners and your stuff safe,
            we'll show you the address of the store once you book online. When you're ready, head to the store and show
            the Bounce Partner your booking confirmation so they can take your bags and securely store them right away.
            Then you can enjoy your day without having to worry about your bags!
            <br />
            <br />
            To pick up your bags, show the Bounce Partner your booking confirmation and they'll hand over your stuff.
            That's it!
          </Trans>
        ),
        structuredAnswer: t(
          "cmp.luggageStorageFaqs.howDoesBounceWork.structuredAnswer",
          "We partner with local businesses around the world who have secure spaces to store luggage for travelers. Just tell us how many bags you have and when you need storage, and we'll show you convenient places you can keep your stuff safe while you explore. Booking online is super easy and only takes a couple of minutes. To keep our partners and your stuff safe, we'll show you the address of the store once you book online. When you're ready, head to the store and show the Bounce Partner your booking confirmation so they can take your bags and securely store them right away. Then you can enjoy your day without having to worry about your bags! To pick up your bags, show the Bounce Partner your booking confirmation and they'll hand over your stuff. That's it!",
        ),
      },
      {
        id: "Does Bounce have luggage lockers near me?",
        question: t("cmp.luggageStorageFaqs.luggageLockers.question", "Does Bounce have luggage lockers near me?"),
        answer: t(
          "cmp.luggageStorageFaqs.luggageLockers.answer",
          "Instead of using luggage lockers, our partners have a secure area in their business to store and watch over your stuff. It’s as safe and easy as using lockers, but without having to worry if they’re available or large enough to fit your luggage.",
        ),
      },
      {
        id: "What can I store with Bounce?",
        question: t("cmp.luggageStorageFaqs.whatCanIStoreWithBounce.question", "What can I store with Bounce?"),
        answer: (
          <Trans i18nKey="cmp.luggageStorageFaqs.whatCanIStoreWithBounce.answer">
            Luggage, backpacks, gym bags, and more! Bounce is like having a personal storage facility for your stuff
            anywhere you go, so you can leave much more than just luggage with us. Whether you want to store your
            luggage after checking out of your Airbnb or want to drop off your work bag before hitting happy hour, we've
            got you covered.
            <br />
            <br />
            We don't charge extra for oversized items, but some special items might need to be approved before you can
            store them. For example, surfboards, guitars, car seats, bicycles, or skis take up a lot of space, so let us
            know if you have them. We can check with the Bounce Partner for you to make sure they have room for your
            stuff.
          </Trans>
        ),
        structuredAnswer: t(
          "cmp.luggageStorageFaqs.whatCanIStoreWithBounce.structuredAnswer",
          "Luggage, backpacks, gym bags, and more! Bounce is like having a personal storage facility for your stuff anywhere you go, so you can leave much more than just luggage with us. Whether you want to store your luggage after checking out of your Airbnb or want to drop off your work bag before hitting happy hour, we've got you covered. We don't charge extra for oversized items, but some special items might need to be approved before you can store them. For example, surfboards, guitars, car seats, bicycles, or skis take up a lot of space, so let us know if you have them. We can check with the Bounce Partner for you to make sure they have room for your stuff.",
        ),
      },
      {
        id: "Are there size limits?",
        question: t("cmp.luggageStorageFaqs.sizeLimits.question", "Are there size limits?"),
        answer: t(
          "cmp.luggageStorageFaqs.sizeLimits.answer",
          "We don't have size limits, but unusually large or bulky items like surfboards, skis, or bicycles should be approved before you store them. To make sure that our partners have room for your stuff, we'll reach out to them for you and let you know.",
        ),
      },
      isBackpacksEnabled
        ? {
            id: "Are small bags less expensive?",
            question: t("cmp.luggageStorageFaqs.areSmallBagsLessExpensive.question", "Are small bags less expensive?"),
            answer: t(
              "cmp.luggageStorageFaqs.areSmallBagsLessExpensive.answer",
              "Yes! We don't charge as much when you store smaller bags like purses, briefcases, and backpacks.",
            ),
          }
        : {
            id: "Are large bags more expensive?",
            question: t("cmp.luggageStorageFaqs.areLargeBagsMoreExpensive.question", "Are large bags more expensive?"),
            answer: t(
              "cmp.luggageStorageFaqs.areLargeBagsMoreExpensive.answer",
              "Nope. All of our bags are priced the same, regardless of bag size. Storing your things with Bounce is affordable, safe, and flexible.",
            ),
          },
      {
        id: "Does Bounce have hourly luggage storage?",
        question: t("cmp.luggageStorageFaqs.hourlyStorage.question", "Does Bounce have hourly luggage storage?"),
        answer: t(
          "cmp.luggageStorageFaqs.hourlyStorage.answer",
          "We charge per 24 hours of luggage storage, not per hour. You'll pay the same price whether you need luggage storage for a few hours or the whole day.",
        ),
      },
      {
        id: "How long can I leave my luggage with Bounce?",
        question: t(
          "cmp.luggageStorageFaqs.howLongCanILeaveLuggage.question",
          "How long can I leave my luggage with Bounce?",
        ),
        answer: (
          <Trans i18nKey="cmp.luggageStorageFaqs.howLongCanILeaveLuggage.answer">
            Whether you need storage for a couple of hours or a couple of days, we've got you covered! Just put in the
            dates and times you need luggage storage and we'll show you all of the available locations on our map. For
            long-term storage lasting more than a week, reach out to us and we'll help you find a convenient location.
            <br />
            <br />
            If you're already storing your stuff but you need more time, you can add more time from your booking details
            page. Just make sure the store will be open and you can get your bags before your new pickup time.
          </Trans>
        ),
        structuredAnswer: t(
          "cmp.luggageStorageFaqs.howLongCanILeaveLuggage.structuredAnswer",
          "Whether you need storage for a couple of hours or a couple of days, we've got you covered! Just put in the dates and times you need luggage storage and we'll show you all of the available locations on our map. For long-term storage lasting more than a week, reach out to us and we'll help you find a convenient location. If you're already storing your stuff but you need more time, you can add more time from your booking details page. Just make sure the store will be open and you can get your bags before your new pickup time.",
        ),
      },
      {
        id: "How do I change or cancel my booking?",
        question: t("cmp.luggageStorageFaqs.changeOrCancelBooking.question", "How do I change or cancel my booking?"),
        answer: t(
          "cmp.luggageStorageFaqs.changeOrCancelBooking.answerV2",
          "Plans change, we get it! If you need to change or cancel your booking for any reason, the best way to do it is from your booking details page in the Bounce app. You can edit the dates, drop-off and pick-up times, and number of bags. You can also cancel your booking or rebook at a nearby store. If you can't find your booking details, check the email address you used to make your booking. You can change your booking by following the link there, or reach out to us if you need support.",
        ),
      },
      {
        id: "Can I cancel and get a refund?",
        question: t("cmp.luggageStorageFaqs.cancellationsAndRefunds.question", "Can I cancel and get a refund?"),
        answer: t(
          "cmp.luggageStorageFaqs.cancellationsAndRefunds.answerV2",
          "Yes! If you change your mind, you can cancel your booking anytime before drop-off time for a full refund. When you cancel less than an hour before your booking starts, your refund will be in Bounce credits. Otherwise, we'll refund you to your original payment method. And we never charge cancellation fees!",
        ),
      },
    ],
    [isBackpacksEnabled, t],
  )

  return faqs
}
