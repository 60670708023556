import classNames from "classnames"
import React from "react"

import type { Nullable } from "@bounce/util"

import type { StepsProps } from "./Steps"
import { Steps } from "./Steps"

export type HowItWorksProps = {
  title?: Nullable<string>
  subtitle?: Nullable<string>
  titleAs?: "h2" | "h3"
  children?: React.ReactNode
  sectionRef?: React.Ref<HTMLElement>
  imageClassName?: string
  className?: string
  id?: string
} & Pick<StepsProps, "steps">

export const HowItWorks = ({
  title,
  subtitle,
  steps,
  titleAs: TitleAs = "h2",
  children,
  sectionRef,
  imageClassName,
  className,
}: HowItWorksProps) => (
  <section
    ref={sectionRef}
    className={classNames("w-full bg-neutral-100 pt-12 pb-section md:pt-16 xl:pt-20", className)}>
    <div className="mx-auto w-full max-w-screen-xl px-6">
      <TitleAs className="title2">{title}</TitleAs>
      {!!subtitle && <p className="paragraph1 mt-2 max-w-4xl text-neutral-800 md:mt-2.5 xl:mt-3">{subtitle}</p>}
      <Steps
        steps={steps}
        titleAs={TitleAs === "h3" ? "h4" : undefined}
        className="mt-8 md:mt-9 xl:mt-12"
        imageClassName={imageClassName}
      />
      {children}
    </div>
  </section>
)
